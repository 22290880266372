import React from "react";

class Dropdown extends React.Component
{
    render()
    {


        let links = this.props.links;
        let href = this.props.href;
        let title = this.props.title;

        if (links == null)
        {
          return(
            <div>
              <a className={"dropdown-title"} href={href}>{title}</a>
            </div>

          )
        }

        let contents = [];

        links.forEach((link) => {
            contents.push(<a className={"dropdown-button transparent"} href={link.href}>{link.name}</a>)
        });


        return(
            <div className={"dropdown"}>
                <div className={"dropdown-title"}>{title}</div>
                <div className={"dropdown-content"}>
                    {contents}
                </div>
            </div>
        )
    }
}

export default Dropdown;
