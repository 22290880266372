import React from "react";

class Card extends React.Component
{
    render()
    {
        const title = this.props.card_info.title;
        const description = this.props.card_info.description;
        const iconName = this.props.card_info.icon;

        const buttonName = this.props.card_info.button_name;
        const buttonHref = this.props.href;

        return(
            <div className="info-card">
                <span className="material-icons">
                    {iconName}
                </span>
                <h2 className="info-card-title">
                    {title}
                </h2>
                <p className={"info-card-description"}>
                    {description}
                </p>
                <a className={"button info-card-button"} href={buttonHref}>{buttonName}</a>

            </div>
        )
    }
}

export default Card;